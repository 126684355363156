import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import {
  bobbyCalvesErrored,
  createOrUpdateBooking,
  createOrUpdateBookingCompleted,
  loadPickups,
  loadPickupsCompleted,
  loadProperties,
  loadPropertiesCompleted,
  loadPropertyCalendar,
  loadPropertyCalendarCompleted,
  seasonComplete,
  seasonCompleteCompleted,
} from './actions';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Bobby } from '../models';
import { NavigationService } from 'src/app/services/navigation.service';
import { deactivateBobbyMenu, loadBobbyCalvesTallyCompleted } from 'src/app/user/state/actions';
import { Store } from '@ngrx/store';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class BobbyEffects {
  constructor(
    private readonly actions: Actions,
    private readonly store: Store,
    private readonly http: HttpClient,
    public readonly navigationService: NavigationService,
  ) {}

  public createOrUpdateBooking$ = createEffect(() =>
    this.actions.pipe(
      ofType(createOrUpdateBooking),
      switchMap((command) =>
        this.http
          .post<Bobby.PotentialOutageResponse<Bobby.CreateOrUpdateBookingResponse>>(bobbyUrl('bookings-outage'), command)
          .pipe(
            map((res) => {
              return createOrUpdateBookingCompleted({
                errored: res.errored,
                response: {
                  propertyId: command.anzcoPropertyId,
                  calendarDateId: command.calendarDateId,
                  note: command.note,
                  tally: command.requestedNumOfCalves,
                  contactNumber: command.contactNumber,
                  isSeasonCompleted: command.isSeasonCompleted,
                  response: res.errored ? null : res.response,
                }
              });
            }),
          ),
      ),
    ),
  );

  public createOrUpdateBookingEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(createOrUpdateBookingCompleted),
      map((res) => bobbyCalvesErrored({ errored: res.errored })),
    ),
  );

  public seasonComplete$ = createEffect(() =>
    this.actions.pipe(
      ofType(seasonComplete),
      switchMap((command) =>
        this.http
          .post<Bobby.PlainPotentialOutageResponse>(bobbyUrl('season-completed-outage'), command)
          .pipe(
            map((res) => seasonCompleteCompleted(res)),
          ),
      ),
    ),
  );

  public loadPickups$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadPickups),
      map(({ propertyId }) => toParams({ propertyId })),
      switchMap((params) =>
        this.http
          .get<Bobby.PotentialOutageResponse<Bobby.PickupsByPropertyResponse>>(bobbyUrl('pickups-outage'), params)
          .pipe(
            map((res) => {
              const mappedResult: Bobby.PotentialOutageResponse<Bobby.PickupsByPropertyResponse> = {
                errored: res.errored,
                response: {
                  hasSeasonStarted: !res.errored && res.response.hasSeasonStarted,
                  hasSeasonEnded: !res.errored && res.response.hasSeasonEnded,
                  isSeasonCompleted: !res.errored && res.response.isSeasonCompleted,
                  userName: res.errored ? '' : res.response.userName,
                  pickups: (res.errored ? [] : res.response.pickups).map((pickup) => {
                    const mappedPickup: Bobby.PickupsByPropertyPickupResponse = {
                      anzcoPropertyId: pickup.anzcoPropertyId,
                      calendarDateId: pickup.calendarDateId,
                      contactNumber: pickup.contactNumber,
                      status: pickup.status,
                      pickupDate: new Date(pickup.pickupDate),
                      bookingsOpensAt: new Date(pickup.bookingsOpensAt),
                      bookingsCloseAt: new Date(pickup.bookingsCloseAt),
                      bookingReminderAt: new Date(pickup.bookingReminderAt),
                      declarationExpected: pickup.declarationExpected,
                      booking: pickup.booking,
                    };

                    return mappedPickup;
                  }),
                }
              };

              return loadPickupsCompleted(mappedResult);
            })
          )
      )
    )
  );

  public loadPickupsErrorEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadPickupsCompleted),
      map((res) => bobbyCalvesErrored({ errored: res.errored })),
    ),
  );

  public loadProperties$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadProperties),
      switchMap(() =>
        this.http
          .get<Bobby.PotentialOutageResponse<Bobby.PropertiesResponse>>(bobbyUrl('properties-outage'))
          .pipe(map((res) => {
            return loadPropertiesCompleted(res);
          }))
      ),
    ),
  );

  public loadPropertiesErrorEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadPropertiesCompleted),
      map((res) => {
        if (!res.errored && res.response.properties.length == 0) {
          this.store.dispatch(deactivateBobbyMenu());
          this.navigationService.home();
        }
        return bobbyCalvesErrored({ errored: res.errored })}
      ),
    ),
  );

  public loadPropertyCalendar$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadPropertyCalendar),
      map(({ propertyId }) => toParams({ propertyId })),
      switchMap((params) =>
        this.http
          .get<Bobby.PotentialOutageResponse<Bobby.PropertyCalendarResponse>>(bobbyUrl('property-calendar-outage'), params)
          .pipe(
            map((res) => {
              const mappedResult: Bobby.PotentialOutageResponse<Bobby.PropertyCalendarResponse> = {
                errored: res.errored,
                response: res.errored ?
                  null :
                  {
                    anzcoPropertyId: res.response.anzcoPropertyId,
                    bobbyCalvesBuyerName: res.response.bobbyCalvesBuyerName,
                    bobbyCalvesBuyerPhone: res.response.bobbyCalvesBuyerPhone,
                    buyerName: res.response.buyerName,
                    buyerPhone: res.response.buyerPhone,
                    propertyName: res.response.propertyName,
                    transportCompanies: res.response.transportCompanies,
                    pickupDates: res.response.pickupDates.map(pickupDate => new Date(pickupDate)),
                  },
              };

              return loadPropertyCalendarCompleted(mappedResult);
            })
          )
      )
    )
  );

  public loadPropertyCalendarErrorEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadPropertyCalendarCompleted),
      map((res) => bobbyCalvesErrored({ errored: res.errored })),
    ),
  );

  public loadBobbyCalvesTallyErrorEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadBobbyCalvesTallyCompleted),
      map((res) => bobbyCalvesErrored({ errored: res.tallies.errored })),
    ),
  );
}

const bobbyUrl = (...segments: any[]) =>
  [`${environment.apiUrl}/api/bobby`, ...segments].join('/');

const toParams: (obj: any) => { params: HttpParams } = (obj: any) => ({
  params: new HttpParams({ fromObject: obj }),
});
