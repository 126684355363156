<div class="default-container" *ngIf="advice">
    <div class="headline">
        <h2>Advice</h2>
        <button mat-raised-button
                class="cancel-button"
                (click)="goBack()">
            Back
        </button>
    </div>

    <div class="centered-content">
        <mat-card>
            <mat-card-content>
                <div class="data-block">
                    <div [class]="advice.cardBackgroundClass">
                        <label>Status: </label>
                        <label>{{ advice.adviceStatus }}</label>
                    </div>
                    <div>
                        <label>Advice Number: </label>
                        <label>{{ advice.advice }}</label>
                    </div>
                    <div>
                        <label>Property: </label>
                        <label>{{ advice.propertyName }}</label>
                    </div>
                    <div *ngIf="advice.feedbackComments">
                        <label>Comments: </label>
                        <label>{{ advice.feedbackComments }}</label>
                    </div>
                    <div>
                        <label>Season: </label>
                        <label>{{ advice.season }}</label>
                    </div>
                    <div *ngIf="advice.isGrazing"
                         class="botton-highlight">
                        <label>Grazing</label>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngFor="let adviceLine of advice.adviceLines">
            <mat-card-content>
                <div class="data-block">
                    <div>
                        <label>{{ adviceLine.stockType }}</label>
                        <img [src]="adviceLine.imageSrc" />
                    </div>
                    <div>
                        <label>Date: </label>
                        <label>{{ adviceLine.killDate | date: "dd/MM/yyyy" }}</label>
                    </div>
                    <div>
                        <label>Average Weight</label>
                        <label>{{ adviceLine.averageWeight ? (adviceLine.averageWeight | number: "1.1-1") : "-" }} kg</label>
                    </div>
                    <div>
                        <label>Total Weight</label>
                        <label>{{ adviceLine.killedPaidWeight ? (adviceLine.killedPaidWeight | number: "1.1-1") : "-" }} kg</label>
                    </div>
                    <div *ngIf="!advice.isGrazing && adviceLine.killDollarPerKg">
                        <label>$/Head</label>
                        <label>NZ$ {{ adviceLine.paidPerHead ? (adviceLine.paidPerHead | number: "1.2-2") : "-" }}</label>
                    </div>
                    <div *ngIf="!advice.isGrazing && adviceLine.paidPricePerKg">
                        <label>$/kg</label>
                        <label>NZ$ {{ adviceLine.paidPricePerKg ? (adviceLine.paidPricePerKg | number: "1.2-2") : "-" }}</label>
                    </div>
                    <div>
                        <label *ngIf="adviceLine.condemnedTally">Tally*</label>
                        <label *ngIf="!adviceLine.condemnedTally">Tally</label>
                        <label>{{ adviceLine.tally ? (adviceLine.tally | number) : "-" }}</label>
                    </div>
                    <div *ngIf="adviceLine.condemnedTally">
                        <label>* Includes <strong>{{ adviceLine.condemnedTally }}</strong> condemned</label>
                    </div>
                    <div *ngIf="!advice.isGrazing && adviceLine.totalNettAmount"
                         class="botton-highlight">
                        <label>Invoice (excl GST)</label>
                        <label>NZ$ {{ adviceLine.totalNettAmount ? (adviceLine.totalNettAmount | number: "1.2-2") : "-" }}</label>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="advice.booking">
            <mat-card-content>
                <div class="data-block">
                    <div class="bobby-calves">
                        <label>Booking Details</label>
                    </div>
                    <div>
                        <label>Requested Tally</label>
                        <label>{{ advice.booking.requestedTally }}</label>
                    </div>
                    <div>
                        <label>Approved Tally</label>
                        <label>{{ advice.booking.confirmedTally }}</label>
                    </div>
                    <div>
                        <label>Completed By</label>
                        <label>{{ advice.booking.recordedBy }}</label>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="!advice.booking && advice.bookingDisplayMessage">
          <mat-card-content>
            <div class="data-block">
              <div class="bobby-calves">
                <label>Booking Details</label>
              </div>
              <div class="text">
                {{ advice.bookingDisplayMessage }}
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="advice.declaration">
            <mat-card-content>
                <div class="data-block">
                    <div class="bobby-calves">
                        <label>Supplier Declaration Details</label>
                    </div>
                    <div>
                        <label>Declared Tally</label>
                        <label>{{ advice.declaration.tally }}</label>
                    </div>
                    <div>
                        <label>Last Fed At</label>
                        <label>{{ advice.declaration.timeOfLastFeed }}</label>
                    </div>
                    <div>
                        <label>Completed By</label>
                        <label>{{ advice.declaration.recordedBy }}</label>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="!advice.declaration && advice.declarationDisplayMessage">
          <mat-card-content>
            <div class="data-block">
              <div class="bobby-calves">
                <label>Supplier Declaration Details</label>
              </div>
              <div class="text">
                {{ advice.declarationDisplayMessage }}
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="advice.pickup">
            <mat-card-content>
                <div class="data-block">
                    <div class="bobby-calves">
                        <label>Transporter Declaration Details</label>
                    </div>
                    <div>
                        <label>Picked Up Tally</label>
                        <label>{{ advice.pickup.tally }}</label>
                    </div>
                    <div>
                        <label>Picked Up At</label>
                        <label>{{ advice.pickup.pickedUpTime }}</label>
                    </div>
                    <div>
                        <label>Rejected Calves</label>
                        <label>{{ advice.pickup.rejected }}</label>
                    </div>
                    <div>
                        <label>Reject Reasons</label>
                        <label>{{ advice.pickup.rejectReasons }}</label>
                    </div>
                    <div>
                        <label>Transport Driver</label>
                        <label>{{ advice.pickup.driverName }}</label>
                    </div>
                    <div>
                        <label>Transporter</label>
                        <label>{{ advice.pickup.transportName ? advice.pickup.transportName : "N/A" }}</label>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="!advice.pickup && advice.pickupDisplayMessage">
          <mat-card-content>
            <div class="data-block">
              <div class="bobby-calves">
                <label>Transporter Declaration Details</label>
              </div>
              <div class="text">
                {{ advice.pickupDisplayMessage }}
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="advice.invoiceFileId || advice.hasCarcaseFiles">
            <mat-card-content>
                <div class="data-block">
                    <div class="highlight">
                        <label>Downloads</label>
                    </div>

                    <div class="downloads">
                        <div *ngIf="advice.invoiceFileId"
                             class="download-icon"
                             matRipple
                             [ngClass]="{ divider: advice.hasCarcaseFiles }"
                             (click)="downloadInvoice($event)">
                            <mat-icon aria-hidden="false" aria-label="Invoice icon">
                                <span class="material-icons"> monetization_on </span>
                            </mat-icon>
                            <p>Invoice</p>
                        </div>

                        <div *ngIf="advice.hasCarcaseFiles"
                             class="download-icon"
                             matRipple
                             (click)="downloadCarcaseData($event)">
                            <mat-icon aria-hidden="false" aria-label="Chart icon">
                                <span class="material-icons-outlined"> insert_chart_outlined </span>
                            </mat-icon>
                            <p>Carcase Data</p>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
