import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import {
  msalClientConfig,
  msalGuardConfiguration,
  msalInterceptorConfiguration,
} from './auth-config';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent, AdminModule } from './admin-features';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as features from './features/index';
import {
  AngularMaterialModule,
  ErrorSnackBarComponent,
  GlobalErrorHandler,
  HttpHeaderInterceptor,
  LoaderModule,
} from './miscellaneous';
import { UserModule } from './user';
import { DefaultNavigationComponent } from './routing/default-navigation.component';
import { FaqComponent, LegalComponent } from './features';
import { AppContentComponent } from './app-content.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { BobbyCalvesModule } from './features/bobby-calves/bobby-calves.module';
import { MyOspriTileComponent } from './features/home/myospri-tile';
import { OrderByPipe } from './order-by-pipe';
import { AdvantageTileComponent, AdvantageTileModule } from './features/home/advantage-tile';
import {
  ComplianceOutageTileComponent,
  NoComplianceTileComponent,
  ComplianceCertifiedTileComponent,
  ComplianceActionRequiredTileComponent,
} from './features/home/compliance-tile';
import { ComplianceModule } from './features/compliance/compliance.module';
import { OutstandingBobbyDeclarationsComponent } from './features/outstanding-bobby-declarations/index';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    features.HelpComponent,
    AdvantageTileComponent,
    ComplianceOutageTileComponent,
    NoComplianceTileComponent,
    ComplianceCertifiedTileComponent,
    ComplianceActionRequiredTileComponent,
    features.HeaderMenuItemsComponent,
    features.AdminUserComponent,
    features.HeaderComponent,
    features.MenuComponent,
    features.MoreComponent,
    features.FooterComponent,
    features.RegistrationShellComponent,
    features.CompleteRegistrationComponent,
    features.RegistrationPendingComponent,
    features.RegistrationHelpComponent,
    features.HomepageShellComponent,
    features.ConfirmationDialogComponent,
    features.DiscardDialogComponent,
    features.NoPropertiesComponent,
    features.BobbyCalvesProgressTileComponent,
    DefaultNavigationComponent,
    ErrorSnackBarComponent,
    FaqComponent,
    AppContentComponent,
    LegalComponent,
    AdvantageTileComponent,
    MyOspriTileComponent,
    OutstandingBobbyDeclarationsComponent,
    OrderByPipe
  ],
  imports: [
    StoreModule.forRoot([]),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    AngularMaterialModule,
    LoaderModule,
    AppRoutingModule,
    AdminModule,
    features.HelpModule,
    features.SpinnerModule,
    features.FooterModule,
    features.AdvicesModule,
    features.StaffManagementModule,
    features.ReportingModule,
    features.NewsModule,
    features.PickupDeclarationsModule,
    AdvantageTileModule,
    BobbyCalvesModule,
    ComplianceModule,
    BrowserModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    UserModule,
    MsalModule.forRoot(
      new PublicClientApplication(msalClientConfig),
      msalGuardConfiguration,
      msalInterceptorConfiguration
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
