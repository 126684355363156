<div class="menu-container">
  <div class="view-container">
    <button class="menu-btn" data-cy="homeMenuItem" (click)="goHome()">
      <mat-icon>home</mat-icon>
      <h1>Home</h1>
    </button>

    <button
      class="menu-btn"
      (click)="goProcessing()"
      data-cy="processingMenuItem">
      <mat-icon>assignment</mat-icon>
      <h1>Processing</h1>
    </button>

    <button
      *ngIf="shouldDisplayBobbyMenu$ | async"
      class="menu-btn"
      (click)="goBobbyCalves()"
      data-cy="bobbyCalveMenuItem">
      <mat-icon class="bobby-calf-icon" svgIcon="bobby-calf"></mat-icon>
      <h1>Bobby Requests</h1>
    </button>

    <button
      *ngIf="shouldDisplayComplianceMenu$ | async"
      class="menu-btn"
      (click)="goCompliance()"
      data-cy="complianceMenuItem">
      <mat-icon>assignment_turned_in</mat-icon>
      <h1>Certifications</h1>
    </button>

    <button
      *ngIf="shouldDisplayReportsMenu$ | async"
      class="menu-btn report-menu-item"
      data-cy="reportingMenuItem"
      (click)="nav.reporting()">
      <mat-icon>assessment</mat-icon>
      <h1>Reporting</h1>
    </button>

    <button
      class="menu-btn border-size"
      data-cy="moreMenuItem"
      (click)="more()">
      <mat-icon>more_horiz</mat-icon>
      <h1>More</h1>
    </button>
  </div>
</div>
