import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  allAdvicesRoute,
  allStaffManagementRoute,
  bobbyCalvesProfileRoute,
  complianceRoute,
  homeRoute,
  moreRoute,
  newsOverviewRoute,
  reportsRoute,
  pickupDeclarationsRoute,
} from '../features';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(
    private readonly router: Router,
  ) {}

  public home(): void {
    this.router.navigate([homeRoute]);
  }

  public processing(): void {
    this.router.navigate([allAdvicesRoute]);
  }

  public news(): void {
    this.router.navigate([newsOverviewRoute]);
  }

  public userManagement(): void {
    this.router.navigate([allStaffManagementRoute]);
  }

  public reporting(): void {
    this.router.navigate([reportsRoute]);
  }

  public bobbyCalves(): void {
    this.router.navigate([bobbyCalvesProfileRoute]);
  }

  public pickupDeclarations(): void {
    this.router.navigate([pickupDeclarationsRoute]);
  }

  public more(): void {
    this.router.navigate([moreRoute]);
  }

  public compliance(): void {
    this.router.navigate([complianceRoute]);
  }
}