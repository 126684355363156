import { Component } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectShouldDisplayReportsMenu,
  selectShouldDisplayBobbyMenu,
} from '../../user';
import { closeMenu } from 'src/app/user/state/actions';
import { selectShouldDisplayComplianceMenu } from 'src/app/user/state/selector';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  public readonly shouldDisplayReportsMenu$: Observable<boolean> =
    this.store.select(selectShouldDisplayReportsMenu);

  public readonly shouldDisplayBobbyMenu$: Observable<boolean> =
    this.store.select(selectShouldDisplayBobbyMenu);

  public readonly shouldDisplayComplianceMenu$: Observable<boolean> =
      this.store.select(selectShouldDisplayComplianceMenu);

  constructor(
    public readonly nav: NavigationService,
    private readonly store: Store,
  ) {}

  public goHome(): void {
    this.store.dispatch(closeMenu());
    this.nav.home();
  }

  public goProcessing(): void {
    this.store.dispatch(closeMenu());
    this.nav.processing();
  }

  public goBobbyCalves(): void {
    this.store.dispatch(closeMenu());
    this.nav.bobbyCalves();
  }

  public goCompliance(): void {
    this.store.dispatch(closeMenu());
    this.nav.compliance();
  }

  public goReporting(): void {
    this.store.dispatch(closeMenu());
    this.nav.reporting();
  }

  public more(): void {
    this.store.dispatch(closeMenu());
    this.nav.more();
  }
}
